import React from "react"
import Layout from "../components/layout"

export default function Contact() {
  return (
    <Layout>
      <p>
        For any queries please contact:
      </p>
      <h2>
        Ian Johnson
      </h2>
      <br></br>
      <h5 className={"emailResize"}>
        Email:<br></br>
        <a href="mailto:ian.johnson@crimbleconsulting.co.uk">ian.johnson@crimbleconsulting.co.uk</a>
      </h5>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
    </Layout>
  )
}